import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import caretRight from '../img/icons/caret-right-purple.svg'
import leadershipBackground from '../img/homepage/diagonal-lines.png'

const LeadershipComponent = ({ data }) => (
  <div className="LeadershipComponent"
    style={{
      backgroundColor: "#DDDDDD",
        backgroundImage: `url(${leadershipBackground})`,
        backgroundPosition: `top left`,
      }}
    >
    <h2 className="LeadershipComponent-title">{data.title}</h2>
    <div className="LeadershipComponent-quotes">
      {data.quotes.map(quote => (
        <div className="LeadershipComponent-quote">
          <div className="LeadershipComponent-quote-content">
            <p className="LeadershipComponent-quote-body">{quote.quote}</p>
            <p className="LeadershipComponent-quote-attribution">
              <Link
                className={`LeadershipComponent-quote-attribution-link`}
                to={quote.linkURL}
              >
                {quote.attribution}
                <img src={caretRight} className="LeadershipComponent-quote-attribution-link-icon" alt="" aria-hidden="true" />
              </Link>
            </p>
          </div>
          <div className="LeadershipComponent-quote-image">
            <PreviewCompatibleImage imageInfo={quote.image} />
          </div>
        </div>
      ))}
    </div>
  </div>
)

LeadershipComponent.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default LeadershipComponent
