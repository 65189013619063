import React from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const ArticleTemplate = ({
  content,
  contentComponent,
  intro,
  image,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div>
      {helmet || ''}
      <div className="Hero">
        <div className="l-padding">
          <div className="l-wrap">
            <Link to="/" className="Hero-back">Back</Link>
            <h1 className="Hero-title">{title}</h1>
          </div>
        </div>
      </div>
      <div className="l-padding">
        <div className="l-wrap">
          <div className="l-extend u-mt5">
            {image}
          </div>
          <p className="Hero-intro u-mt5">{intro}</p>
          <PostContent content={content} className="u-richtext u-mt5" />
        </div>
      </div>
    </div>
  )
}

ArticleTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  intro: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const Article = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ArticleTemplate
        content={post.html}
        contentComponent={HTMLContent}
        intro={post.frontmatter.intro}
        helmet={
          <Helmet titleTemplate="%s | PAN Foundation">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="intro"
              content={`${post.frontmatter.intro}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image=
          {post.frontmatter.image ? (
            <div className="Hero-image">
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.frontmatter.image,
                  alt: `featured image thumbnail for post ${
                    post.title
                  }`,
                }}
              />
            </div>
          ) : null}
      />
    </Layout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Article

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        intro
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
