import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import caretRight from '../img/icons/caret-right.svg'

const ThreeUp = ({ blockItems }) => (
  <ul className="ThreeUpStat">
    {blockItems.map(item => (
      <li key={item.statistic} className="ThreeUpStat-item">
        <div className="ThreeUpStat-image">
          <PreviewCompatibleImage imageInfo={item.image} />
        </div>
        <p className="ThreeUpStat-statistic">{item.statistic}</p>
        <p className="ThreeUpStat-description">{item.description}</p>
        <p className="u-mt2">
          <Link
            className={`ThreeUpStat-link`}
            to={item.linkURL}
          >
            {item.linkText}
            <img src={caretRight} className="ThreeUpStat-link-icon" alt="" aria-hidden="true" />
          </Link>
        </p>
      </li>
    ))}
  </ul>
)

ThreeUp.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default ThreeUp
