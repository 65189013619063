import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import statPatients from '../img/who-we-served/stat-patients.svg'
import statGrant from '../img/who-we-served/stat-grant.svg'
import womanIcon from '../img/who-we-served/female-icon.svg'
import manIcon from '../img/who-we-served/male-icon.svg'
import ageChart from '../img/who-we-served/piechart-age.png'
import illnessChart from '../img/who-we-served/chart-illness.png'
import patientIncome from '../img/who-we-served/patient-income.png'
import stateMap from '../img/who-we-served/states-map.png'
import stateListings from '../img/who-we-served/state-listings.png'

export const WhoWeServedPageTemplate = ({
  title,
  summary,
  illnessText,
  insuranceText,
  patientPovertyText,
  patientStateText
}) => {

  return (
    <div>
      <div className="Hero">
        <div className="l-padding">
          <div className="l-wrap">
            <Link to="/" className="Hero-back">Back</Link>
            <h1 className="Hero-title">{title}</h1>
          </div>
        </div>
      </div>
      <div className="l-padding">
        <div className="About l-wrap u-mt5 u-richtext">
          <p className="About-summary l-extend">{summary}</p>
          <div className="About-stats l-extend">
            <div className="About-stats-item">
              <div className="About-stat-image About-stat-image--heart">
                <img src={statPatients} alt="" aria-hidden="true" />
              </div>
              <p className="About-stat-info">Total patients assisted</p>
              <p className="About-stat-statistic">182,615</p>
            </div>
            <div className="About-stats-item">
              <div className="About-stat-image">
                <img src={statGrant} alt="" aria-hidden="true" />
              </div>
              <p className="About-stat-info">Average grant amount</p>
              <p className="About-stat-statistic">$5,751</p>
            </div>
          </div>
          <h2 className="u-mt6">Gender</h2>
          <div className="About-gender">
            <div className="About-gender-item">
              <div className="About-gender-image">
                <img src={manIcon} alt="" aria-hidden="true" />
              </div>
              <p className="About-gender-statistic">50%</p>
              <p className="About-gender-info">Male</p>
            </div>
            <div className="About-gender-item">
              <div className="About-gender-image About-gender-image--woman">
                <img src={womanIcon} alt="" aria-hidden="true" />
              </div>
              <p className="About-gender-statistic">50%</p>
              <p className="About-gender-info">Female</p>
            </div>
          </div>
          <h2 className="u-mt6">Age</h2>
          <div className="About-age">
            <div className="About-age-image About-age-item">
              <img src={ageChart} alt="" aria-hidden="true" />
            </div>
            <div className="About-age-legend About-age-item">
              <p className="About-age-info About-age-young">Under 65: 17.7%</p>
              <p className="About-age-info About-age-old">Over 65: 82.3%</p>
            </div>
          </div>
          <h2 className="u-mt6">Type of Illness</h2>
          <p>{illnessText}</p>
            <div className="About-illness">
              <div class="About-image">
                <img src={illnessChart} alt="A chart showing cancer as just over 50% of cases, chronic as just under 50% of cases, and very few rare cases." />
              </div>
              <div className="About-illness-legend">
                <p className="About-illness-info About-illness-info--cyan">Cancer</p>
                <p className="About-illness-info About-illness-info--seafoam">Chronic</p>
                <p className="About-illness-info About-illness-info--pink">Rare</p>
              </div>
            </div>

          <h2 className="u-mt6">Insurance Type</h2>
          <p>{insuranceText}</p>
          <div class="About-insurance l-extend">
            <div className="About-insurance-item">
              <p className="About-insurance-item-text">Medicare</p>
              <p className="About-insurance-item-percent">97.1%</p>
            </div>
            <div className="About-insurance-item">
              <p className="About-insurance-item-text">Commercial</p>
              <p className="About-insurance-item-percent">2.5%</p>
            </div>
            <div className="About-insurance-item">
              <p className="About-insurance-item-text">Other</p>
              <p className="About-insurance-item-percent">0.4%</p>
            </div>
          </div>
          <h2 className="u-mt6">Patient Income by Federal Poverty Level</h2>
          <p>{patientPovertyText}</p>
          <div className="About-poverty-image About-poverty-item">
            <img src={patientIncome} alt="" aria-hidden="true" />
          </div>
          <h2 className="u-mt6">Patients Enrolled by State</h2>
          <p>{patientStateText}</p>
          <div className="About-poverty-image About-poverty-image--desktop About-poverty-item">
            <img src={stateMap} alt="" aria-hidden="true" />
          </div>
          <div className="About-poverty-image About-poverty-image--mobile About-poverty-item">
            <img src={stateListings} alt="" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>

  )
}

WhoWeServedPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  illnessText: PropTypes.string,
  insuranceText: PropTypes.string,
  patientPovertyText: PropTypes.string,
  patientStateText: PropTypes.string,
}

const WhoWeServedPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <WhoWeServedPageTemplate
        title={post.frontmatter.title}
        summary={post.frontmatter.summary}
        illnessText={post.frontmatter.illnessText}
        insuranceText={post.frontmatter.insuranceText}
        patientPovertyText={post.frontmatter.patientPovertyText}
        patientStateText={post.frontmatter.patientStateText}
      />
    </Layout>
  )
}

WhoWeServedPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WhoWeServedPage

export const aboutPageQuery = graphql`
  query WhoWeServedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        summary
        illnessText
        insuranceText
        patientPovertyText
        patientStateText
      }
    }
  }
`
