import React from 'react'
// import { Link } from 'gatsby'

// import logo from '../img/logo.png'
// import facebook from '../img/social/facebook.svg'
// import instagram from '../img/social/instagram.svg'
// import twitter from '../img/social/twitter.svg'
// import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.pageClasses()
    }, '250ms' )
  }

  pageClasses() {
    if (typeof window !== 'undefined' && window.location.pathname === "/") {
      return "--home"
    }
  }

  render() {
    return (
      <footer className={`Footer Footer${this.pageClasses()}`}>
        <p className="Footer-copyright">&#169; Copyright 2019 Patient Access Network Foundation. All rights reserved.</p>
      </footer>
    )
  }
}

export default Footer
