import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import caretRight from '../img/icons/caret-right-purple.svg'

const RecognitionPromo = ({ data }) => (
  <div className="RecognitionPromo">
    <div className="l-padding">
      <div className="l-wrap-wide">
        <div className="RecognitionPromo-wrapper">
          <div className="RecognitionPromo-content RecognitionPromo-item">
            <h2 className="RecognitionPromo-title">{data.title}</h2>
            <p className="RecognitionPromo-description">{data.description}</p>
            <p className="RecognitionPromo-cta">
              <Link
                className={`RecognitionPromo-cta-link`}
                to={data.linkURL}
              >
                {data.linkText}
                <span className="u-screenreader"> about our compliance program</span>
                <img src={caretRight} className="RecognitionPromo-cta-link-icon" alt="" aria-hidden="true" />
              </Link>
            </p>
            <a className="RecognitionPromo-link" href="{data.linkURL}" aria-hidden="true" tabIndex="-1"></a>
          </div>
          <div className="RecognitionPromo-image RecognitionPromo-item">
            <PreviewCompatibleImage imageInfo={data.image} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

RecognitionPromo.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default RecognitionPromo
