import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo-white.png'
// import LeadershipLetters from './LeadershipLetters'
// import Highlights from './Highlights'
// import Spotlights from './Spotlights'
import ExpandToggle from "@threespot/expand-toggle";
import scroll from "@threespot/freeze-scroll";

const NavbarHome = class extends React.Component {
  constructor(props) {
    super(props);
    this.initToggleMenu = this.initToggleMenu.bind(this);
  }

  componentDidMount() {
    this.initToggleMenu();
    this.initGTM();
  }

  initToggleMenu() {
    const el = document.querySelector('[data-expands]');
    const links = document.querySelectorAll('.Nav-menu-sublist-item-link');

    const toggle = new ExpandToggle(el)

    toggle.on('expand', function() {
      document.querySelector('.Nav-menu-toggle-icon').classList.add('is-active');
      scroll.freeze();
    });

    toggle.on('collapse', function() {
      document.querySelector('.Nav-menu-toggle-icon').classList.remove('is-active');
      scroll.unfreeze();
    })

    links.forEach(el => {
      el.addEventListener('click', (evt) => {
        toggle.collapse();
      })
    })
  }

  initGTM() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-N6Z2Z8G');
  }

  render() {
    return (
      <nav
        className={`Nav Nav--home`}
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="Nav-wrapper">
          <Link to="/" className="Nav-logo Nav-item" title="Logo">
            <img src={logo} alt="PAN Foundation logo" />
          </Link>
          {/* Hamburger menu */}
          <button
            className={`Nav-menu-toggle Nav-menu-toggle--home Nav-item`}
            data-expands="navMenu"
            data-expands-class="is-expanded"
            data-expands-height
          >
          <span data-expands-text="Table of Contents" className={`Nav-menu-toggle-item Nav-menu-toggle-item--home f-menu-title`}>Menu</span>
          <span className={`Nav-menu-toggle-icon Nav-menu-toggle-item`}></span>
        </button>
        </div>
        <div
          id="navMenu"
          className={`Nav-menu expandable`}
        >
          <ul className="Nav-menu-list">
            <li className="Nav-menu-list-item">
              <p className="Nav-menu-list-item-title">Leadership Letters</p>
              <ul className="Nav-menu-sublist">
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/letter-from-martin-bieber-chairperson/"
                  >
                    Letter from Martin Bieber, Chairperson
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/letter-from-daniel-klein-president-and-ceo/"
                  >
                    Letter from Dan Klein, President and CEO
                  </Link>
                </li>
              </ul>
            </li>
            <li className="Nav-menu-list-item">
              <p className="Nav-menu-list-item-title">Highlights</p>
              <ul className="Nav-menu-sublist">
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/advocating-on-behalf-of-patients/"
                  >
                    Advocating on Behalf of Patients
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/educating-and-engaging-new-supporters/"
                  >
                    Educating and Engaging New Supporters
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/increasing-access-to-treatment-through-technology/"
                  >
                    Increasing Access to Treatment Through Technology
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/simplifying-the-search-for-assistance-with-fundfinder/"
                  >
                    Simplifying the Search for Assistance with FundFinder
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/bolstering-our-best-in-class-compliance-program/"
                  >
                    Bolstering Our Best-in-Class Compliance Program
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/impact-of-patient-assistance-on-access-medication-adherence-and-quality-of-life/"
                  >
                    Impact of Patient Assistance on Access, Medication Adherence and Quality of Life
                  </Link>
                </li>
              </ul>
            </li>
            <li className="Nav-menu-list-item">
              <p className="Nav-menu-list-item-title">Spotlights</p>
              <ul className="Nav-menu-sublist">
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/patient-spotlight-an-artist-living-with-metastatic-breast-cancer/"
                  >
                    Patient Spotlight: An Artist Living With Metastatic Breast Cancer
                  </Link>
                </li>
                <li className="Nav-menu-sublist-item">
                  <Link
                    className="Nav-menu-sublist-item-link"
                    to="/articles/provider-spotlight-navigating-hope-on-the-cancer-journey/"
                  >
                    Provider Spotlight: Navigating Hope on the Cancer Journey
                  </Link>
                </li>
              </ul>
            </li>
            <li className="Nav-menu-sublist-item">
              <Link
                className="Nav-menu-sublist-item-link"
                to="/2018-financials"
              >
                2018 Financials
              </Link>
            </li>
            <li className="Nav-menu-sublist-item">
              <Link
                className="Nav-menu-sublist-item-link"
                to="/who-we-served"
              >
                Who We Served in 2018
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default NavbarHome
