import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import showdown from 'showdown'

import contributionsIcon from '../img/financials/contribution-icon.svg'
import patientsIcon from '../img/financials/patientsassisted-icon.png'
import expensesGraph from '../img/financials/total-expenses.png'

const converter = new showdown.Converter()

export const FinancialsPageTemplate = ({
  title,
  contributions,
  expenses
}) => (
  <div>
    <div className="Hero">
      <div className="l-padding">
        <div className="l-wrap">
          <Link to="/" className="Hero-back">Back</Link>
          <h1 className="Hero-title">{title}</h1>
        </div>
      </div>
    </div>
    <div className="l-padding">
      <div className="Financials l-wrap u-mt5 u-richtext">
        <div className="Financials-stat l-extend">
          <div className="Financials-stat-image Financials-stat-image--large">
            <img src={patientsIcon} alt="An image showing 182,615 patients were assisted, and $530.7 million contributions were reported." />
          </div>
        </div>
        <div
          className="u-richtext u-mt5"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(contributions) }}
        />
        <h2>Expenses</h2>
        <h3>Total 2018 Expenses</h3>
        <div className="Financials-graph">
          <div className="Financials-graph-image Financials-graph-item">
            <img src={expensesGraph} alt="A graph showing the breakdown of expenses in 2018." />
          </div>
          <div className="Financials-graph-legend Financials-graph-item">
            <p className="Financials-graph-info Financials-graph--pink">Fundraising: 0.2%</p>
              <p className="Financials-graph-info Financials-graph--cyan">Administrative: 1.5%</p>
            <p className="Financials-graph-info Financials-graph--blue">Other program expenses: 2.1%</p>
              <p className="Financials-graph-info Financials-graph--purple">Co-pay expenses: 96.2%</p>
          </div>
        </div>
        <div
          className="u-richtext u-mt5"
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(expenses) }}
        />
      </div>
    </div>
  </div>
)

FinancialsPageTemplate.propTypes = {
  title: PropTypes.string,
  contributions: PropTypes.string,
  expenses: PropTypes.string,
}

const FinancialsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <FinancialsPageTemplate
      title={frontmatter.title}
      contributions={frontmatter.contributions}
      expenses={frontmatter.expenses}

      />
    </Layout>
  )
}

FinancialsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FinancialsPage

export const productPageQuery = graphql`
  query FinancialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        contributions
        expenses
      }
    }
  }
`
