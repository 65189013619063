import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Media from 'react-media';

import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import backgroundVideoWebm from '../video/hero-bg.webm'
import threeUpBackground from '../img/homepage/three-up-background.png'

import ThreeUp from '../components/ThreeUp'
import LeadershipComponent from '../components/LeadershipComponent'
import AdvocatePromo from '../components/AdvocatePromo'
import SearchPromo from '../components/SearchPromo'
import RecognitionPromo from '../components/RecognitionPromo'
import TestimonialPromo from '../components/TestimonialPromo'
import LookingForwardPromo from '../components/LookingForwardPromo'

export const IndexPageTemplate = ({
  supertitle,
  title,
  image,
  subtitle,
  valueStatement,
  threeUpStat,
  leadershipComponent,
  advocatePromo,
  searchPromo,
  recognitionPromo,
  testimonialPromo,
  lookingForwardPromo
}) => (
  <div style={{
      backgroundColor: '#fff',
      position: 'relative',
      zIndex: '0',
    }}>
    <div
      className="Billboard"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
      }}
    >
      <Media query="(min-width: 768px)">
        {matches =>
          matches ? (
            <div dangerouslySetInnerHTML={{ __html: `
              <video
                muted
                autoplay
                src="${backgroundVideoWebm}"
                style="bottom:0; height:100%; left:50%; position:absolute; right:0; top:50%; transform:translate(-50%, -50%); width:120%;"
                />
              ` }}
            />
        ) : ( <div></div> )
        }
      </Media>
      <div className="Billboard-wrapper">
        <p className="Billboard-supertitle">{supertitle}</p>
        <h1 className="Billboard-title">{title}</h1>
        <p className="Billboard-subtitle">{subtitle}</p>
      </div>
    </div>
    <div className="l-padding"
      style={{
        background: 'rgb(248,246,243)',
        background: 'linear-gradient(180deg, rgba(248,246,243,1) 0%, rgba(255,255,255,1) 100%)',
        backgroundImage: `url(${threeUpBackground})`,
        backgroundRepeat: `no-repeat`,
        overflow: `visible`,
      }}
    >
      <div className="l-wrap-wide">
        <div className="Values">
          <div className="Values-image Values-item">
            <PreviewCompatibleImage imageInfo={valueStatement.image} />
          </div>
          <div className="Values-item">
            <p className="Values-body">{valueStatement.bodyText}</p>
            <p className="Values-source">
              <a className="Values-source-link" href={valueStatement.sourceLink}>
                {valueStatement.sourceText}
              </a>
            </p>
          </div>
          <div className="Values-statement Values-item">
            {valueStatement.statement}
          </div>
        </div>

        <ThreeUp blockItems={threeUpStat.blocks} />

        <LeadershipComponent data={leadershipComponent} />
      </div>
    </div>

    <AdvocatePromo data={advocatePromo} />

    <SearchPromo data={searchPromo} />

    <RecognitionPromo data={recognitionPromo} />

    <TestimonialPromo data={testimonialPromo} />

    <LookingForwardPromo data={lookingForwardPromo} />
  </div>
)

IndexPageTemplate.propTypes = {
  homepage: true,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  supertitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  valueStatement: PropTypes.object,
  threeUpStat: PropTypes.shape({
    blocks: PropTypes.array,
  }),
  leadershipComponent: PropTypes.object,
  advocatePromo: PropTypes.object,
  searchPromo: PropTypes.object,
  recognitionPromo: PropTypes.object,
  testimonialPromo: PropTypes.object,
  lookingForwardPromo: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        supertitle={frontmatter.supertitle}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        valueStatement={frontmatter.valueStatement}
        threeUpStat={frontmatter.threeUpStat}
        leadershipComponent={frontmatter.leadershipComponent}
        advocatePromo={frontmatter.advocatePromo}
        searchPromo={frontmatter.searchPromo}
        recognitionPromo={frontmatter.recognitionPromo}
        testimonialPromo={frontmatter.testimonialPromo}
        lookingForwardPromo={frontmatter.lookingForwardPromo}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        supertitle
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subtitle
        valueStatement {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bodyText
          sourceText
          sourceLink
          statement
        }
        threeUpStat {
          blocks {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            statistic
            description
            linkText
            linkURL
          }
        }
        leadershipComponent {
          title
          quotes {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            quote
            attribution
            linkURL
          }
        }
        advocatePromo {
          title
          linkURL
          description
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          promos {
            icon {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            linkURL
          }
        }
        searchPromo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkText
          linkURL
        }
        recognitionPromo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          linkText
          linkURL
        }
        testimonialPromo {
          title
          quotes {
            quote
            attribution
            linkURL
          }
        }
        lookingForwardPromo {
          title
          summary
          description
          ctas {
            icon {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cta
            linkURL
          }
        }
      }
    }
  }
`
