import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import caretRight from '../img/icons/caret-right-cyan.svg'
import leadershipBackground from '../img/homepage/diagonal-lines.png'

const TestimonialPromo = ({ data }) => (
  <div className="TestimonialPromo"
    style={{
      backgroundColor: "#fff",
        backgroundImage: `url(${leadershipBackground})`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
    <div className="l-padding">
      <div className="l-wrap-wide">
        <h2 className="TestimonialPromo-title">{data.title}</h2>
        <div className="TestimonialPromo-quotes">
          {data.quotes.map(quote => (
            <div className="TestimonialPromo-quote">
              <p className="TestimonialPromo-quote-body">{quote.quote}</p>
              <p className="TestimonialPromo-quote-attribution">
                <Link
                  className={`TestimonialPromo-quote-attribution-link`}
                  to={quote.linkURL}
                >
                  {quote.attribution}
                  <img src={caretRight} className="TestimonialPromo-quote-attribution-link-icon" alt="" aria-hidden="true" />
                </Link>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

TestimonialPromo.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default TestimonialPromo
