import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import caretRight from '../img/icons/caret-right.svg'

const SearchPromo = ({ data }) => (
  <div className="SearchPromo">
    <div className="SearchPromo-image SearchPromo-item">
      <PreviewCompatibleImage imageInfo={data.image} />
    </div>
    <div className="SearchPromo-content SearchPromo-item">
      <h2 className="SearchPromo-title">{data.title}</h2>
      <p className="SearchPromo-description">{data.description}</p>
      <p className="SearchPromo-cta">
        <Link
          className={`SearchPromo-cta-link`}
          to={data.linkURL}
        >
          {data.linkText}
          <span className="u-screenreader"> about searching for assistance</span>
          <img src={caretRight} className="SearchPromo-cta-link-icon" alt="" aria-hidden="true" />
        </Link>
      </p>
      <a className="SearchPromo-link" href="{data.linkURL}" aria-hidden="true" tabIndex="-1"></a>
    </div>

  </div>
)

SearchPromo.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default SearchPromo
