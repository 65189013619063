import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import caretRight from '../img/icons/caret-right-white.svg'

const AdvocatePromo = ({ data }) => (
  <div className="AdvocatePromo"
    style={{
        backgroundImage: `url(${
          !!data.backgroundImage.childImageSharp ? data.backgroundImage.childImageSharp.fluid.src : data.backgroundImage
        })`,
        backgroundPosition: `center center`,
        backgroundSize: `cover`,
      }}
    >
    <div className="l-padding">
      <div className="l-wrap-wide">
        <div className="AdvocatePromo-titleblock">
          <a className="AdvocatePromo-titleblock-link" href={data.linkURL} aria-hidden="true" tabIndex="-1"></a>
          <h2 className="AdvocatePromo-title">
            <a className="AdvocatePromo-promo-title-link" href={data.linkURL}>
              {data.title}
            </a>
          </h2>
          <p className="AdvocatePromo-description">{data.description}</p>
        </div>
        <div className="AdvocatePromo-promos">
          {data.promos.map(promo => (
            <div className="AdvocatePromo-promo">
              <a className="AdvocatePromo-promo-link" href={promo.linkURL} aria-hidden="true" tabIndex="-1"></a>
              <div className="AdvocatePromo-promo-image AdvocatePromo-promo-item">
                <PreviewCompatibleImage imageInfo={promo.icon} />
              </div>
              <div className="AdvocatePromo-promo-content AdvocatePromo-promo-item">
                <h3 className="AdvocatePromo-promo-title">
                  <a className="AdvocatePromo-promo-title-link" href={promo.linkURL}>
                    {promo.title}
                  </a>
                </h3>
                <p className="AdvocatePromo-promo-description">
                  {promo.description}
                  <img src={caretRight} className="AdvocatePromo-promo-description-icon" />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>


  </div>
)

AdvocatePromo.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default AdvocatePromo
