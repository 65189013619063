import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import { Link } from 'gatsby'

import caretRight from '../img/icons/caret-right-white.svg'

const LookingForwardPromo = ({ data }) => (
  <div className="LookingForwardPromo">
    <div className="l-padding">
      <div className=" l-wrap-wide">
        <div className="LookingForwardPromo-wrap">
          <div className="LookingForwardPromo-titleblock LookingForwardPromo-item">
            <h2 className="LookingForwardPromo-title">{data.title}</h2>
              <p className="LookingForwardPromo-description">{data.description}</p>
            <p className="LookingForwardPromo-summary">{data.summary}</p>
          </div>
          <ul className="LookingForwardPromo-ctas LookingForwardPromo-item">
            {data.ctas.map(cta => (
              <li className="LookingForwardPromo-cta">
                <div className="LookingForwardPromo-cta-image LookingForwardPromo-cta-item">
                  <PreviewCompatibleImage imageInfo={cta.icon} />
                </div>
                <div className="LookingForwardPromo-cta-content LookingForwardPromo-cta-item">
                  <p className="LookingForwardPromo-cta-title">
                    <a className="LookingForwardPromo-cta-title-link" href={cta.linkURL}>
                      {cta.cta}
                      <img src={caretRight} className="LookingForwardPromo-cta-title-link-icon" alt="" aria-hidden="true" />
                    </a>
                  </p>
                </div>
                <a className="LookingForwardPromo-promo-link" href={cta.linkURL} aria-hidden="true" tabIndex="-1"></a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

LookingForwardPromo.propTypes = {
  blockItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default LookingForwardPromo
